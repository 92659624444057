<template>
	<div class="pd30">
		<div class="ft20 cl-black cl-main ftw500">轮播图管理</div>
		<div class="mt20">
			<div class="pd40 bg-w">
				<div>
					<a-button type="primary" icon="plus" @click="AddAct()">新增轮播图</a-button>
				</div>

				<div class="mt20">
					<div class=" ">
						<a-form layout="inline">
							<a-form-item label="">
								<a-input v-model="search.info" placeholder="请输入内容名称"></a-input>
							</a-form-item>
							<a-form-item label="">
								<a-select placeholder="请选择类型" style="width: 120px" @change="handleChange" :allowClear="true">
									<a-select-option :value=1>
										首页
									</a-select-option>
									<a-select-option :value=2>
										商城
									</a-select-option>
								</a-select>
							</a-form-item>

							<a-form-item>
								<a-button @click="searchAct" type="primary">查询</a-button>
								<!-- <a-button @click="cancelAct" class="ml10">取消</a-button> -->
							</a-form-item>
						</a-form>
					</div>
				</div>

				<div class="mt20">

					<div class="wxb-table-gray">
						<a-table rowKey="look_id" :columns="columns" :pagination="pagination" @change="handleTableChange"
							:data-source="datas" :loading="loading">
							<div class="flex center " slot="photos" slot-scope="photos">
								<div style="position: relative;">
									<img v-if="photos.length > 0" :src="photos[0]" class="look-index-item-cover-img" />

									<div class="look-index-item-cover-img-num">{{ photos.length }}张</div>
								</div>
							</div>

							<div class="flex alcenter center" slot="see_num" slot-scope="see_num,record">
								{{ see_num }}次
							</div>

							<div class="flex alcenter center" slot="likes_num" slot-scope="likes_num,record">
								{{ likes_num }}次
							</div>


							<template slot="action" slot-scope="record">
								<div class="flex center">
									<a-dropdown placement="bottomRight">
										<span class="more-act">
											<i class="iconfont iconmore_gray"></i>
										</span>
										<a-menu slot="overlay">
											<a-menu-item>
												<a class="menu-act" href="javascript:;" @click="EditAct(record)">
													<i class="iconfont ft14 iconedit"></i>
													<span class="ml10">编辑</span>
												</a>
											</a-menu-item>
											<a-menu-item>
												<a class="menu-act" href="javascript:;" @click="delAct(record)">
													<i class="iconfont ft14 icondelete"></i>
													<span class="ml10">删除</span>
												</a>
											</a-menu-item>
										</a-menu>
									</a-dropdown>
								</div>
							</template>
						</a-table>
					</div>

				</div>

			</div>
		</div>
	</div>
</template>

<script>
import {
	listMixin
} from '../../common/mixin/list.js';
export default {
	mixins: [listMixin],
	data() {
		return {
			loading: false,
			search: {
				info: '',
				type: '',
			},
			columns: [
				{ title: '相轮播图id', dataIndex: 'look_id', align: 'center', ellipsis: true },
				{ title: '封面', dataIndex: 'photos', align: 'center', scopedSlots: { customRender: 'photos' } },
				{ title: '说明', dataIndex: 'info', align: 'center', ellipsis: true },
				{ title: '跳转链接', dataIndex: 'url', align: 'center', ellipsis: true },
				{
					title: '类型',
					dataIndex: 'type',
					align: 'center',
					customRender: (text) => {
						if (text == 1) {
							return '首页'
						}
						if (text == 2) {
							return '商城'
						}
					}
				},
				{ title: '商品id', dataIndex: 'goods_ids', align: 'center', ellipsis: true },
				// {title: '浏览次数',dataIndex: 'see_num',align: 'center',scopedSlots: {customRender: 'see_num'}},
				// {title: '点赞次数',dataIndex: 'likes_num',align: 'center',scopedSlots: {customRender: 'likes_num'}},
				{ title: '操作人名称', dataIndex: 'operate_name', align: 'center', ellipsis: true },
				{ title: '操作人手机号', dataIndex: 'operate_mobile', align: 'center', ellipsis: true },
				{ title: '添加时间', dataIndex: 'add_time_format', align: 'center', ellipsis: true },
				{ title: '操作', key: 'action', align: 'center', scopedSlots: { customRender: 'action' } },
			],
			datas: [],
		}
	},
	methods: {

		getLists() {
			if (this.loading == true) return;
			this.loading = true;
			this.$http.api('admin/getLookList', {
				limit: this.pagination.pageSize,
				page: this.pagination.current,
				info: this.search.info,
				type: this.search.type,
			}).then(res => {
				this.pagination.total = res.total;
				this.datas = res.list;
				this.loading = false;
			}).catch(res => {
				console.log(res);
				this.loading = false;
			})
		},

		delAct(record) {
			this.$confirm({
				title: '确认删除这个相册吗？',
				okText: "确定",
				okType: "danger",
				cancelText: "取消",
				onOk: () => {
					return new Promise((resolve, reject) => {
						this.$http.api('admin/delLook', {
							look_id: record.look_id,
						}).then(res => {
							this.$message.success('删除成功');
							this.getLists();
						}).catch(res => {
							console.log(res);
						}).finally(() => {
							resolve();
						})
					})
				}
			})
		},

		AddAct() {
			this.$router.push('/look/edit');
		},
		EditAct(record) {
			this.$router.push('/look/edit?id=' + record.look_id);
		},
		handleTableChange(pagination, filters, sorter) {
			this.pagination.current = pagination.current;
			this.getLists();
		},
		handleChange(value) {
			this.search.type = value
		},
	}
}
</script>

<style>
@media screen and (max-width: 1450px) {
	.look-index-item {
		border: 1px solid #EBEDF5;
		width: 99%;
		height: 140px;
		margin-right: 1%;
		margin-bottom: 1%;
		padding: 10px;
	}
}

@media screen and (min-width:1451px) {
	.look-index-item {
		border: 1px solid #EBEDF5;
		width: 49%;
		height: 140px;
		margin-right: 1%;
		margin-bottom: 1%;
		padding: 10px;
	}
}

.look-index-item:hover {
	border: none;
	box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.04);
}

.look-index-item-operate {
	display: none;
}

.look-index-item:hover .look-index-item-operate {
	display: block;
}

.look-index-item-cover-img {
	width: 120px;
	height: 80px;
}

.look-index-item-cover-img-num {
	position: absolute;
	right: 18px;
	bottom: 8px;

	background: rgba(0, 0, 0, 0.5);
	border-radius: 2px;
	padding: 1px 6px;
	font-size: 12px;
	font-weight: 400;
	color: #FFFFFF;
	opacity: 0.5;
}

.look-index-item-operate-button {
	width: 40px;
	height: 40px;
	border-radius: 40px;
	background: #FFFFFF;
	border: 1px solid #EBEDF5;
	cursor: pointer;
}
</style>
